import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import "./AgeVerification.css"; // Import the CSS file

export default function AgeVerification() {
  const [showModal, setShowModal] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [cookies, setCookie] = useCookies(["age-verified", "location"]);
  const [underAge, setUnderAge] = useState(false);

  const handleAgeVerified = (valid) => {
    if (valid) {
      setCookie("age-verified", true, { maxAge: rememberMe ? 2592000 : 90000 });
      setShowModal(false);
      setUnderAge(false);
    } else {
      setUnderAge(true);
      alert("Please leave and come back when you are of age. Thank you");
    }
  };

  useEffect(() => {
    if (cookies["age-verified"] === "true") {
      //console.log("verified");
    } else {
      setShowModal(true);
    }
  }, [cookies]);

  useEffect(() => {
    document.body.style.overflow = showModal ? "hidden" : "unset";
  }, [showModal]);

  return (
    <>
      {showModal && (
        <>
          <div className="modal-overlay">
            <div className="modal-content">
              <div className="modal-header">
                <img
                  alt="logo"
                  className="logo"
                  src={require("assets/img/logo2.png")}
                />
              </div>
              <div className="modal-body">
                <h3 className="modal-title">ONLY BECAUSE WE HAVE TO ASK</h3>
                <p className="modal-text">
                  BEFORE ENTERING THIS WEBSITE YOU MUST BE OF LEGAL AGE IN
                  ACCORDANCE WITH THE LAW OF YOUR PROVINCE
                </p>
                <div className="remember-me">
                  <input
                    id="avcb"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label htmlFor="avcb">Remember Me For 30 Days</label>
                </div>
                <div className="modal-actions">
                  <button
                    className="btn yes-btn"
                    style={{
                      border: "1px solid #000000",
                      backgroundColor: "#E45293",
                    }}
                    onClick={() => handleAgeVerified(true)}
                  >
                    Yes, I'M LEGAL AGE
                  </button>
                  <button
                    className="btn no-btn"
                    onClick={() => handleAgeVerified(false)}
                  >
                    NO, I'M NOT
                  </button>
                </div>
              </div>
              <div className="modal-footer">
                <img
                  alt="auth"
                  className="auth-image"
                  src={require("assets/img/authENFR2.png")}
                />
              </div>
            </div>
          </div>
          <div className="modal-backdrop"></div>
        </>
      )}
    </>
  );
}
