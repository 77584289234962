/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoChevronDownSharp } from "react-icons/io5";
import { IoChevronUpSharp } from "react-icons/io5";

import { useCookies } from "react-cookie";
import StoreList from "components/Helpers/StoreList";

export default function Navbar({ fixed }) {
  const history = useNavigate();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["age-verified"]);
  const [underAge, setUnderAge] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const ageVerifiedCookie = cookies["age-verified"];

    if (ageVerifiedCookie !== undefined && ageVerifiedCookie === "true") {
      setUnderAge((s) => (s = false));
    }
  }, [cookies]);

  const routeChange = (pathName) => {
    if (!underAge) {
      if (pathName == "/memberships") {
        let membershipURL =
          "https://join.mywallet.deals/?id=c437257f-71b2-45a7-9190-3f91cf78de8a&m_location=/#/login";
        window.open(membershipURL, "_blank", "noopener,noreferrer");
      } else if (pathName === "/giftcards") {
        let giftCardURL = "https://card.birchmountnetwork.com/store/tcann";
        window.open(giftCardURL, "_blank", "noopener,noreferrer");
      } else {
        history(pathName);
      }
    } else {
      console.log("!verified IN NAV BAR", cookies["age-verified"]);
    }
  };

  return (
    <>
      <nav className="top-0  z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-green shadow">
        <div className="container px-4 md:px-0 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <button
              className=""
              onClick={() => {
                routeChange("/");
              }}
            >
              <img
                alt="..."
                className="inline-block navLogo"
                src={require("assets/img/logo.png")}
              />
            </button>
            <button
              className="w-1/4 cursor-pointer text-xl leading-none px-3 py-1 rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars text-white"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-green lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={() => {
                    routeChange("/");
                  }}
                >
                  <h1 className="text-white navText">HOME</h1>
                </button>
              </li>

              <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={() => {
                    routeChange("/stores");
                  }}
                >
                  <h1 className="text-white navText">STORES</h1>
                </button>
              </li>

              <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={() => {
                    routeChange("/giftcards");
                  }}
                >
                  <h1 className="text-white navText">GIFT CARDS</h1>
                </button>
              </li>

              {/* <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={() => {
                    routeChange("/memberships");
                  }}
                >
                  <h1 className="text-white navText">MEMBERSHIPS</h1>
                </button>
              </li> */}

              <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={() => {
                    routeChange("/contact");
                  }}
                >
                  <h1 className="text-white navText">CONTACT</h1>
                </button>
              </li>

              <li className="flex items-center">
                <button
                  style={{
                    paddingTop: "11px",
                    paddingBottom: "11px",
                    paddingLeft: "19px",
                    paddingRight: "19px",
                  }}
                  className="text-white bg-lightBlue-600 text-xs font-bold uppercase rounded shadow outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    routeChange("/stores");
                  }}
                >
                  <h1
                    style={{
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    Order Now
                  </h1>
                </button>
              </li>
              <li className="large-screen-image px-3">
                <div
                  className=""
                  style={{ position: "relative", width: "260px" }}
                  onClick={toggleDropdown}
                >
                  <h2 className="bg-lightBlue-600 font-bold text-md text-black px-6 text-center">
                    You are shopping at:
                  </h2>
                  <h3
                    className="p-3 flex justify-between items-center text-white bg-green border-white border-2 cursor-pointer"
                    // style={{ backgroundColor: "#121212" }}
                  >
                    <img
                      alt="..."
                      width={40}
                      src={require("assets/img/location.png")}
                    />
                    {/* <GrLocation size={25} />
                    <path
                      className="cls-1"
                      d="M25.32,75.31a3.59,3.59,0,1,1,0,7.18H18.58L9.7,108.07H112.93l-9.64-25.58H97.72a3.59,3.59,0,0,1,0-7.18h10.7l14.46,39.92H0L13.32,75.31Z"
                    /> */}
                    <span className="px-3 pr-7">Ontario</span>
                    {isDropdownOpen ? (
                      <IoChevronUpSharp size={20} />
                    ) : (
                      <IoChevronDownSharp size={20} />
                    )}
                  </h3>
                </div>
                {isDropdownOpen && (
                  <div
                    className="bg-white p-3"
                    style={{
                      position: "absolute",
                      top: "100px",
                      width: "260px",
                      zIndex: "1000",
                      maxHeight: "228px",
                      overflowY: "auto",
                    }}
                  >
                    {StoreList.map((store, index) => (
                      <div>
                        <h2 className="text-lightBlue-900 font-bold">
                          {store.title}
                        </h2>
                        <p className="text-xs w-64" style={{ width: "180px" }}>
                          {store.location.address}
                        </p>
                        <p className="text-xs w-64 mb-1">{store.phoneNumber}</p>
                        <a href={store.href} target="_blank">
                          <button className="bg-lightBlue-600 p-1 text-xs text-white font-bold mb-3">
                            SELECT STORE
                          </button>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
