import React, { useEffect } from "react";
import PropTypes from "prop-types";

export default function CardLocationItem({
  locationAddress,
  locationTitle,
  locationHours,
  locationPhoneNumber,
  href,
}) {
  const goToOrderPage = (locationId) => {
    window.open(locationId, "_blank");
  };

  return (
    <>
      <div className="bg-white w-full relative flex flex-col min-w-0 break-words rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <div className=" container flex flex-row">
                <div className="container mx-auto">
                  <div className="flex flex-col md:flex-row text-left md:text-center items-center md:pt-0 py-2">
                    <div className="">
                      <img
                        alt="..."
                        style={{
                          height: "100%",
                          width: "100%",
                          maxWidth: "80px",
                        }}
                        src={require("assets/img/logo2.png")}
                      />
                    </div>
                    <div
                      className="px-2 text-xl md:pt-0 text-black font-semibold"
                      style={{
                        fontWeight: 500,
                        fontSize: "24px",
                        lineHeight: "29px",
                        textTransform: "capitalize",
                      }}
                    >
                      <p>{locationTitle}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="block font-semibold text-sm leading-relaxed text-black "
                style={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "20px",
                  textTransform: "capitalize",
                }}
              >
                <a
                  target={"_blank"}
                  href={"http://maps.google.com/?q=" + locationAddress}
                >
                  {locationAddress}
                </a>
              </div>
              {
                <>
                  <div
                    className="font-semibold text-sm leading-relaxed text-black py-2"
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "20px",
                      textTransform: "capitalize",
                    }}
                  >
                    <a href={"tel:" + locationPhoneNumber}>
                      {locationPhoneNumber}
                    </a>
                  </div>

                  <div
                    className="font-semibold text-sm leading-relaxed text-black"
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "20px",
                      textTransform: "capitalize",
                    }}
                  >
                    Hours:
                  </div>

                  <div
                    className=" pb-2 font-bold text-sm leading-relaxed"
                    style={{
                      color: "#E95094",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "20px",
                      textTransform: "capitalize",
                    }}
                  >
                    {locationHours.regular}
                  </div>
                  {locationHours.regular2 && (
                    <div
                      className=" pb-2 font-bold text-sm leading-relaxed"
                      style={{
                        color: "#E95094",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "20px",
                        textTransform: "capitalize",
                      }}
                    >
                      {locationHours.regular2}
                    </div>
                  )}

                  <div className="pt-2 font-semibold text-xl text-blueGray-700">
                    <button
                      onClick={() => {
                        goToOrderPage(href);
                      }}
                      className="w-full get-started text-white font-bold  py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    >
                      Order Now
                    </button>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CardLocationItem.defaultProps = {
  locationAddress: "Traffic",
  locationTitle: "350,897",
  statArrow: "up",
  statPercent: "3.48",
  statPercentColor: "text-emerald-500",
  locationHours: "Since last month",
  statIconName: "far fa-chart-bar",
  statIconColor: "bg-red-500",
};

CardLocationItem.propTypes = {
  locationAddress: PropTypes.string,
  locationTitle: PropTypes.string,
  locationHours: PropTypes.string,
};
