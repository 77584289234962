/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import AgeVerification from "components/AgeVerification";
import { useNavigate } from "react-router-dom";
export default function StockBanner() {
  const [showModal, setShowModal] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["age-verified"]);
  const [underAge, setUnderAge] = useState(false);
  let history = useNavigate();

  useEffect(() => {
    //console.log(cookies['age-verified'])
    if (cookies["age-verified"] == true) {
      //console.log("verified");
    } else {
      //console.log("!verified");
    }
  }, [cookies]);

  const routeChange = (pathName) => {
    //console.log("routeChanged called ");
    if (!underAge) {
      if (pathName == "/membership") {
        let membershipURL =
          "https://join.mywallet.deals/?id=c437257f-71b2-45a7-9190-3f91cf78de8a&m_location=/#/login";
        window.open(membershipURL, "_blank", "noopener,noreferrer");
      } else if (pathName == "/careers") {
        let careersURL =
          "https://docs.google.com/forms/d/e/1FAIpQLSfeqmotU-7Ko8SFYVzBHIp4JgnF3APcWB2yKpi_LpiBHzj2zA/viewform";
        window.open(careersURL, "_blank", "noopener,noreferrer");
      } else {
        history(pathName);
      }
    } else {
      //console.log("!verified IN NAV BAR",cookies['age-verified']);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="header relative  items-center flex flex-wrap  max-h-860-px tcantheme">
      <div className="container mx-auto justify-center flex-row items-center flex w-full">
        {/* <div className="w-full flex-row lg:p-5 px-2 text-center md:text-center">       */}
        <div className="flex flex-col md:flex-row items-center  py-2">
          <div className="flex flex-row">
            <div className="px-2 items-center flex">
              <span className="font-market">CSE:</span>
              <span className="font-ticker">EPIC</span>
            </div>

            <div className="px-2 items-center flex ">
              <span className="font-market">OTC:</span>
              <span className="font-ticker">MILFF</span>
            </div>
          </div>

          <div className="px-2 flex items-center flex justify-center">
            <span className="font-market">
              <a href="https://1cminc.com/invest" target="_blank">
                Learn More &gt;
              </a>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
