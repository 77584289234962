import React, { useEffect,useState } from "react";
import GoogleMapReact from "google-map-react";
import { StoreMarker } from "./StoreMarker";
import StoreList from "components/Helpers/StoreList";

function Map({mapLocation,orderStoreList}) {
  const mapRef = React.useRef(null);
  const [center, setCenter] = useState({  lat:43.630670,lng:-80.040100 });
  
   useEffect(()=>{
      if(mapLocation)
      {
        //console.log("map location updated in map file",mapLocation)
        setCenter({lat:mapLocation.lat,lng:mapLocation.lng});
      }
      else{
        //console.log("no location set in map file",mapLocation);
      }     
    },[mapLocation])




  return (
    <>
      <div className="relative w-full rounded h-full w-full">
        {/* <div className="h-full" ref={mapRef} /> */}
        

        <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAPSRVoEduajBLOEzXIwLLmS602Z5RNldU" }}
        defaultCenter={{ lat: 45.5484, lng: -75.2916 }}
        defaultZoom={10}
        center={center}
        options={{          
          styles: [
            {
              featureType: "poi",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              stylers: [{ visibility: "off" }],
            },            
          ]
        }}
    
      >
        
        {StoreList.map((store,idx)=>{
          return(
            <div 
            onClick={()=>{orderStoreList(store)}}
            key={store.id}
            lat={store.location.lat}
            lng={store.location.lng}
            >
              <StoreMarker              
                
                key={idx}
                lat={store.location.lat}
                lng={store.location.lng}    
              />    
            </div>
          )
        })}
      
        </GoogleMapReact>





      </div>
    </>
  );
}

export default Map;
