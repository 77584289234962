import React from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import Index from "./views/Index";
import Stores from "./views/Stores";
import Order from "./views/Order";
import ContactUs from "./views/ContactUs";
import { initializeApp } from "firebase/app";
import ReactGA from "react-ga";

const firebaseConfig = {
  apiKey: "AIzaSyCyPFIMctBJG24Rh7yRrCE4GYXRev7pkf8",
  authDomain: "tcannabis-aa6e7.firebaseapp.com",
  projectId: "tcannabis-aa6e7",
  storageBucket: "tcannabis-aa6e7.appspot.com",
  messagingSenderId: "905663131956",
  appId: "1:905663131956:web:cfd24127078179041cf561",
  measurementId: "G-M86GVLCYHH",
};

const app = initializeApp(firebaseConfig);
ReactGA.initialize("G-VDBYX7BGL8");

const root = createRoot(document.getElementById("root"));
const renderApp = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/stores" element={<Stores />} />
          <Route path="/" exact element={<Index />} />
          <Route path="*" element={<NotFoundRedirect />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

const NotFoundRedirect = () => {
  const location = useLocation();

  console.log("location: ", location);

  if (location.pathname === "/order") {
    return <Navigate to="/stores" />;
  }

  return <Navigate to="/" />;
};

root.render(renderApp());
