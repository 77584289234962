import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
// components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import Footer from "components/Footers/Footer.js";
import AgeVerification from "components/AgeVerification";
import StockBanner from "components/StockBanner/StockBanner";
import ReactGA from "react-ga";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
import { useCookies } from "react-cookie";
export default function ContactUs() {
  const analytics = getAnalytics();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");
  const [infoText, setInfoText] = useState("");
  const [alertState, setAlertState] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["age-verified"]);

  emailjs.init("vg8d8CBmxlnQZxUjG");

  useEffect(() => {
    window.scrollTo(0, 0);
    //getAnalytics(getApp()).logEvent('page_view', "contact_us",window.location.pathname + window.location.search);
    //logEvent(analytics, 'goal_completion', { name: 'lever_puzzle'});
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleSubmit = () => {
    if (!phoneNumber || !email || !location || !message) {
      setInfoText("Please enter information in all fields");
      setAlertState(true);
      setTimeout(() => {
        setAlertState(false);
      }, 5000);
    }
    // else if (!emailFormat.test(String(email).toLowerCase())) {

    // }
    else {
      emailjs
        .send(
          "1CM_Mailer",
          "template_lci7ecm",
          {
            from_name: name,
            message: `Phone: ${phoneNumber}, Email: ${email}, Location: ${location},  Message: ${message}`,
            reply_to: email,
          },
          "vg8d8CBmxlnQZxUjG"
        )
        .then(() => {
          setInfoText("Thank you! We will reach out to you shortly.");
          setAlertState(true);
          setTimeout(() => {
            setAlertState(false);
          }, 9000);
          ReactGA.event({
            category: "Contact",
            action: "Form Submit",
            value: email,
          });
          //getAnalytics(getApp()).logEvent('page_view', "contact_us",window.location.pathname + window.location.search);
          logEvent(analytics, "user_engagement", { form_submit: email });
        });
    }
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleChangeLocation = (e) => {
    setLocation(e.target.value);
  };
  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  return (
    <>
      {!cookies["age-verified"] && <AgeVerification></AgeVerification>}
      <IndexNavbar />

      <StockBanner></StockBanner>
      <section className="bg-white dark:bg-gray-900 w-full justify-center items-center flex overflow-x-hidden overflow-y-auto currentStoreBannerBG">
        <div className="md:w-10/12 lg:w-10/12 xl:w-6/12 container py-5">
          <div className="contactOutline p-7 lg:py-16 ">
            <h2
              className="mb-4 text-4xl tracking-tight font-extrabold text-left text-gray-900 dark:text-white"
              style={{ fontWeight: 600, fontSize: "46px", color: "white" }}
            >
              Contact Us 👋
            </h2>
            <p
              className="mb-8 lg:mb-16 font-light text-left text-white dark:text-white sm:text-xl"
              style={{ fontWeight: 300, fontSize: "20px", color: "white" }}
            >
              Have a question, comment?
            </p>
            <form action="#" className="space-y-8 ">
              <div className="flex flex-row justify-between">
                <div
                  className="w-full md:w-1/2"
                  style={{ paddingRight: "0.5rem" }}
                >
                  <input
                    type="text"
                    id="name"
                    onChange={handleChangeName}
                    style={{
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                    className="shadow-sm bg-gray-50 font-semibold border border-gray-300   rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Name"
                    required
                  />
                </div>
                <div
                  className="w-full md:w-1/2 pl-1"
                  style={{ paddingLeft: "0.5rem" }}
                >
                  <input
                    type="text"
                    id="email"
                    onChange={handleChangeEmail}
                    style={{
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
              <div className="py-4">
                <input
                  type="text"
                  id="phoneNumber"
                  onChange={handleChangePhoneNumber}
                  style={{
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "24px",
                  }}
                  className="block p-3 w-full font-semibold text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="Phone Number"
                  required
                />
              </div>
              <div className="py-1">
                <select
                  type="text"
                  id="location"
                  onChange={handleChangeLocation}
                  style={{
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "#717171",
                  }}
                  className="block p-3 w-full font-semibold text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="Location"
                  required
                >
                  <option
                    style={{
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                    selected
                  >
                    Location
                  </option>
                  <option value="t-cannabis-acton">Acton</option>
                  <option value="t-cannabis-beamsville">Beamsville</option>
                  <option value="t-cannabis-cochrane">Cochrane</option>
                  <option value="t-cannabis-fort-frances">Fort Frances</option>
                  <option value="t-cannabis-hearst">Hearst</option>
                  <option value="t-cannabis-kenora">Kenora</option>
                  <option value="t-cannabis-kirkland-lake">
                    Kirkland Lake
                  </option>
                  <option value="t-cannabis-new-liskeard">New Liskeard</option>
                  <option value="t-cannabis-renfrew">Renfrew</option>
                  <option value="t-cannabis-rockland">Rockland</option>
                  <option value="t-cannabis-sioux-lookout">
                    Sioux Lookout
                  </option>
                  <option value="t-cannabis-smith-falls">Smiths Falls</option>
                  <option value="t-cannabis-tottenham">Tottenham</option>
                  <option value="t-cannabis-wawa">Wawa</option>
                </select>
              </div>
              <div className="sm:col-span-2 py-4">
                <textarea
                  id="message"
                  rows="6"
                  onChange={handleChangeMessage}
                  style={{
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "24px",
                  }}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Message"
                ></textarea>
              </div>
              <button
                onClick={() => {
                  handleSubmit();
                }}
                className="my-2 w-full get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                style={{ backgroundColor: "#E95094" }}
              >
                Submit
              </button>
              {alertState && (
                <p
                  className="mb-8 lg:mb-16 font-light text-left text-white dark:text-white sm:text-xl"
                  style={{ fontWeight: 300, fontSize: "20px", color: "white" }}
                >
                  {infoText}
                </p>
              )}
            </form>
          </div>
        </div>
      </section>

      <Footer />

      {
        // <!-- Hotjar Tracking Code for my site -->

        (function (h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: 3251138, hjsv: 6 };
          a = o.getElementsByTagName("head")[0];
          r = o.createElement("script");
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
      }
    </>
  );
}
