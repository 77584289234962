import { textAlign } from "@mui/system";
import { useRef, useState } from "react";

export const StoreMarker = ({ key, lat, lng }) => {
  return (
    <>
      {/* <div style={{ transform: 'translate(-25px,-50px)', height: '50px', width: '50px' }}> */}
      <div
        style={{
          transform: "translate(-25px,-50px)",
          height: "75px",
          width: "75px",
        }}
      >
        <img className="w-full" src={require("assets/img/logo2.png")} />
      </div>
    </>
  );
};
