/*eslint-disable*/
import React, { useEffect, useState, Dispatch } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import AgeVerification from "components/AgeVerification";
import StoreSelecter from "components/StoreSelecter";
import Map from "components/Maps/Map";
import CardLocationItem from "components/Cards/CardLocationItem";
import StoreList from "components/Helpers/StoreList";
import StoreSearchBar from "components/Searchbar/StoreSearchBar";
import ReactGA from "react-ga";
import StockBanner from "components/StockBanner/StockBanner";
import { getAnalytics, logEvent } from "firebase/analytics";
import { holidayList } from "components/Helpers/HolidayHelper";
export default function Stores() {
  const analytics = getAnalytics();

  const [showModal, setShowModal] = React.useState(false);
  const [locationModalOpen, setlocationModalOpen] = React.useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([
    "location",
    "age-verified",
  ]);
  const [location, setLocation] = useState("");
  const [mapLocation, setMapLocation] = useState({
    lat: 43.63067,
    lng: -80.0401,
  });
  const [listOfStores, setListOfStores] = useState(StoreList);

  const [isHoliday, setIsHoliday] = useState(false);
  const [holidayDate, setHolidayDate] = useState("");

  const setMapCenter = (loc) => {
    setMapLocation({ lat: loc.lat, lng: loc.lng });
  };
  const setArea = (loc, store) => {
    setMapCenter(loc);

    logEvent(analytics, "user_engagement", { store_card_click: store.id });
  };

  const orderStoreList = (store) => {
    if (store) {
      let idx = listOfStores.indexOf(store);

      let tempVal = listOfStores[idx];
      let tempArr = listOfStores.filter((item) => item.id !== store.id);

      tempArr.unshift(tempVal);

      setListOfStores(tempArr);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {}, [listOfStores]);

  useEffect(() => {
    const today = new Date();
    const datesToCheck = holidayList;

    const matchingDate = datesToCheck.find((date) => {
      const [month, day] = date.split("-");
      return today.getMonth() + 1 == month && today.getDate() == day;
    });

    if (matchingDate) {
      console.log("Holiday Found", matchingDate);
      setHolidayDate(matchingDate);
      setIsHoliday(true);
    } else {
      console.log("Not a HolliDay");
      setIsHoliday(false);
    }
  }, [listOfStores]);

  return (
    <>
      {!cookies["age-verified"] && <AgeVerification></AgeVerification>}

      <IndexNavbar />
      <StockBanner></StockBanner>

      <section className="header relative items-center flex flex-wrap h-full currentStoreBannerBG">
        <div className="flex flex-col h-full md:flex-row w-full h-full">
          {/* List of stores */}
          {/* <div className="pb-2 w-full md:w-4/12 xl:w-1/4 px-3 order-2 md:order-1 storesListHeight h-screen"   > */}
          <div className="pb-2 w-full md:w-4/12 lg:w-4/12 xl:w-1/4 px-3 order-2 md:order-1 storesListHeight">
            <div className="flex flex-col lg:pl-2 ">
              <div className="p-4">
                <h1
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "30px",
                    lineHeight: "37px",
                    color: "#FFFFFF",
                    paddingBottom: "1rem",
                  }}
                >
                  Find A T Cannabis Store
                </h1>
                <StoreSearchBar
                  listOfStores={listOfStores}
                  setListOfStores={setListOfStores}
                  setMapCenter={setMapCenter}
                ></StoreSearchBar>
              </div>
              {listOfStores.map((store, idx) => {
                console.log(store.hours[holidayDate]);

                return (
                  <div
                    className="flex flex-row md:flex-col p-4"
                    key={idx}
                    onClick={() => {
                      setArea(
                        { lat: store.location.lat, lng: store.location.lng },
                        store
                      );
                    }}
                  >
                    {isHoliday && (
                      <CardLocationItem
                        locationTitle={store.title}
                        locationAddress={store.location.address}
                        locationPhoneNumber={store.phoneNumber}
                        locationHours={{ regular: store.hours[holidayDate] }}
                        locationHours2={store.hours.regular2}
                        locationId={store.id}
                        href={store.href}
                      ></CardLocationItem>
                    )}

                    {!isHoliday && (
                      <CardLocationItem
                        locationTitle={store.title}
                        locationAddress={store.location.address}
                        locationPhoneNumber={store.phoneNumber}
                        locationHours={store.hours}
                        locationId={store.id}
                        href={store.href}
                      ></CardLocationItem>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="hidden md:block w-full md:w-8/12 lg:w-8/12 xl:w-3/4 order-3 md:order-2"
            style={{ minHeight: "100vh" }}
          >
            <div className="relative flex flex-col min-w-0 break-words bg-white h-full w-full mb-6 shadow-lg rounded">
              {mapLocation != undefined && (
                <Map
                  mapLocation={mapLocation}
                  orderStoreList={orderStoreList}
                ></Map>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <div className="mt-40rem md:mt-0"> */}
      <div className="block md:mt-0">
        <Footer />
      </div>

      {
        // <!-- Hotjar Tracking Code for my site -->

        (function (h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: 3251138, hjsv: 6 };
          a = o.getElementsByTagName("head")[0];
          r = o.createElement("script");
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
      }
    </>
  );
}
