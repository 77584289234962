export const banners = [
  {
    province: "Acton",
    images: [
      {
        desktop:
          "https://storage.googleapis.com/costcan_website_banners/Ontario/desktop/DesktopBanner01.png",
        mobile:
          "https://storage.googleapis.com/costcan_website_banners/Ontario/mobile/MobileBanner01.png",
        productLink: "https://on.costcan.ca/menu",
      },
    ],
  },
];
