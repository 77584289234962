const StoreList = [
  //Acton
  {
    name: "T Cannabis (Acton)",
    title: "Acton",
    id: "t-cannabis-acton",
    phoneNumber: "(519) 853-9898",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/5fe12432a142c8010650e89e.js",
    location: {
      address: "38 Mill St E,Acton, ON L7J 1H2",
      lat: 43.63067,
      lng: -80.0401,
    },
    hours: {
      regular: "Monday - Sunday: 9 AM - 11 PM",
      "02-20": "9:00 AM - 11:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 10:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/acton-t-cannabis/",
  },
  //Beamsville
  {
    name: "T Cannabis (Beamsville)",
    title: "Beamsville",
    id: "t-cannabis-beamsville",
    phoneNumber: "(905) 563-5255",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/5eb9e4463836aa00b88a6251.js",
    location: {
      address: "3-4322 Ontario St Beamsville, ON L0R 1B5",
      lat: 43.16698,
      lng: -79.4768,
    },
    hours: {
      regular: "Monday - Sunday: 9 AM - 11 PM",
      "02-20": "9:00 AM - 11:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 11:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/beamsville-t-cannabis/",
  },
  {
    name: "T Cannabis (Cochrane)",
    title: "Cochrane",
    id: "t-cannabis-nw-cochrane",
    phoneNumber: "(705) 913-2339",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/60da566de735ef00bcb2c8f1.js",
    location: {
      address: "143 4th St W,Cochrane, ON P0L 1C0",
      lat: 49.062803,
      lng: -81.024914,
    },
    hours: {
      regular: "Monday - Saturday: 9 AM - 11 PM",
      regular2: "Sunday: 11 AM - 6 PM",
      "02-20": "11:00 AM - 6:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 11:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/cochrane-t-cannabis/",
  },
  //Fort Frances
  {
    name: "T Cannabis (Fort Frances)",
    title: "Fort Frances",
    id: "t-cannabis-fort-frances",
    phoneNumber: "(807) 274-2222",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/6022bdd8b61c4d00c787981c.js",
    location: {
      address: "#2 - 130 Second St E,Fort Frances, ON P9A 1M5",
      lat: 48.61196,
      lng: -93.40013,
    },
    hours: {
      regular: "Monday - Sunday: 9 AM - 11 PM",
      "02-20": "11:00 AM - 7:00 PM",
      "03-29": "10:00 AM - 8:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 09:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/fort-frances-t-cannabis/",
  },
  //Hearst
  {
    name: "T Cannabis (Hearst)",
    title: "Hearst",
    id: "t-cannabis-nw-hearst",
    phoneNumber: "(705) 362-7699",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/62424361a02d7c00acddb3d6.js",
    location: {
      address: "3 15th St Unit 5,Hearst, ON P0L1N0",
      lat: 49.690655,
      lng: -83.680482,
    },
    // hours: {
    //   regular: "Sunday - Wednesday: 9 AM - 9 PM , Thurdsday 9 AM - 10 PM ",
    //   regular2: "Friday - Saturday: 9 AM - 11 PM ",
    //   "02-20": "11:00 AM - 7:00 PM",
    //   "03-29": "10:00 AM - 5:00 PM",
    //   "07-01": "🇨🇦 Canada Day: 10:00 AM - 05:00 PM 🇨🇦",
    // },
    hours: {
      regular: "11 AM - 9 PM ",
      "02-20": "11:00 AM - 7:00 PM",
      "03-29": "10:00 AM - 5:00 PM",
      "07-01": "🇨🇦 Canada Day: 10:00 AM - 05:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/hearst-t-cannabis/",
  },
  //Kenora
  {
    name: "T Cannabis (Kenora)",
    title: "Kenora",
    id: "t-cannabis-nw-kenora",
    phoneNumber: "(807) 467-3132",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/60da56fe0fb77300bacdc263.js",
    location: {
      address: "400 2nd St S,Kenora, ON P9N 1G6",
      lat: 49.765357,
      lng: -94.477384,
    },
    hours: {
      regular: "Monday - Sunday: 9 AM - 11 PM",
      "02-20": "9:00 AM - 11:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 11:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/kenora-t-cannabis/",
  },
  //Kirkland Lake
  {
    name: "T Cannabis (Kirkland Lake)",
    title: "Kirkland Lake",
    id: "t-cannabis-nw-kirkland-lake",
    phoneNumber: "(705) 462-6626",

    menuId:
      "https://dutchie.com/api/v2/embedded-menu/60da569417dfe2009d363270.js",
    location: {
      address: "59 Government Road W,Kirkland Lake, ON P2N 2E5",
      lat: 48.148905,
      lng: -80.037841,
    },
    hours: {
      regular: "Monday - Sunday: 9 AM - 10 PM",
      "02-20": "9:00 AM - 10:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 10:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/kirkland-lake-t-cannabis/",
  },
  //New Liskeard
  {
    name: "T Cannabis (New Liskeard)",
    title: "New Liskeard",
    id: "t-cannabis-new-liskeard",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/604fe19833c51e00b841d38d.js",
    phoneNumber: "(705) 628-8828",
    location: {
      address: "9 Armstrong St N,New Liskeard, ON P0J 1P0",
      lat: 47.51198,
      lng: -79.67153,
    },
    hours: {
      regular: "Monday - Saturday: 9 AM - 11 PM",
      regular2: "Sunday: 10 AM - 9 PM",
      "02-20": "9:00 AM - 9:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 09:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/new-liskeard-t-cannabis/",
  },
  //Renfrew
  {
    name: "T Cannabis (Renfrew)",
    title: "Renfrew",
    id: "t-cannabis-renfrew",
    phoneNumber: "(613) 433-9393",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/5fe124633b1a4f00e6963c17.js",
    location: {
      address: "164 Raglan St S,Renfrew, ON K7V 1R1",
      lat: 45.482651,
      lng: -76.698137,
    },
    hours: {
      regular: "Monday - Sunday: 9 AM - 11 PM",
      "03-29": "9:00 AM - 9:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 09:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/renfrew-t-cannabis/",
  },
  //Rockland
  {
    name: "T Cannabis (Rockland)",
    title: "Rockland",
    id: "t-cannabis-rockland",
    phoneNumber: "(613) 446-5416",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/5fe124105e9f1e00c2799f44.js",
    location: {
      address: "1-2865 Chamberland St Rockland, ON K4K 1M7",
      lat: 45.542787,
      lng: -75.302977,
    },
    hours: {
      regular: "Monday - Sunday: 9 AM - 11 PM",
      "02-20": "9:00 AM - 11:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 11:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/rockland-t-cannabis/",
  },
  //Sioux Lookout
  {
    name: "T Cannabis (Sioux Lookout)",
    title: "Sioux Lookout",
    id: "t-cannabis-sioux-lookout",
    phoneNumber: "(807) 737-8888",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/635fcd3a60823d00b26a25d7.js",
    location: {
      address: "37 Front st, Sioux Lookout, ON, P8T 1H4",
      lat: 50.09782,
      lng: -91.91415,
    },
    hours: {
      regular: "Monday - Saturday: 9 AM - 10 PM",
      regular2: "Sunday: 11 AM - 8 PM",
      "02-20": "10:00 AM - 7:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 09:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/sioux-lookout-t-cannabis/",
  },
  //Smiths Falls
  {
    name: "T Cannabis (Smiths Falls)",
    title: "Smiths Falls",
    id: "t-cannabis-smith-falls",
    phoneNumber: "(613) 283-1437",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/5eb9e3b7a8bcda00e0d61903.js",
    location: {
      address: "4 Russell St E,Smiths Falls, ON K7A 1E8",
      lat: 44.90157,
      lng: -76.02095,
    },
    hours: {
      regular: "Monday - Sunday: 9 AM - 11 PM",
      "02-20": "9:00 AM - 11:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 11:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/smiths-falls-t-cannabis/",
  },
  //Tottenham
  {
    name: "T Cannabis (Tottenham)",
    title: "Tottenham",
    id: "t-cannabis-tottenham",
    phoneNumber: "(905) 936-2020",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/624244440abb5d3a9260bb44.js",
    location: {
      address: "54 Queen Street South,Tottenham, ON L0G 1W0",
      lat: 44.0201,
      lng: -79.805451,
    },
    hours: {
      regular: "Monday - Saturday: 9 AM - 11 PM",
      regular2: "Sunday: 9 AM - 10 PM",
      "02-20": "10:00 AM - 8:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 09:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/tottenham-t-cannabis/",
  },
  //Wawa
  {
    name: "T Cannabis (Wawa)",
    title: "Wawa",
    id: "t-cannabis-wawa",
    phoneNumber: "(705) 856-7777",
    menuId:
      "https://dutchie.com/api/v2/embedded-menu/635c46308a312000a44da3f2.js",
    location: {
      address: "152 Mission Rd, Wawa, ON P0S 1K0",
      lat: 47.97812,
      lng: -84.78182,
    },
    hours: {
      regular: "Monday - Saturday: 9 AM - 9 PM",
      regular2: "Sunday: 11 AM - 7 PM",
      "02-20": "11:00 AM - 7:00 PM",
      "03-29": "12:00 PM - 7:00 PM",
      "07-01": "🇨🇦 Canada Day: 9:00 AM - 09:00 PM 🇨🇦",
    },
    href: "https://on.costcan.ca/menu/wawa-t-cannabis/",
  },
];

export default StoreList;
