/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import AgeVerification from "components/AgeVerification";
import { useNavigate } from "react-router-dom";
import StockBanner from "components/StockBanner/StockBanner";
import ReactGA from "react-ga";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { banners } from "strings/Banners";
import { getAnalytics, logEvent } from "firebase/analytics";
import "./index.css";

export default function Index() {
  ReactGA.initialize("G-VDBYX7BGL8");

  const analytics = getAnalytics();
  const [rememberMe, setRememberMe] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["age-verified"]);

  let navigate = useNavigate();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  const routeChange = (pathName) => {
    logEvent(analytics, "user_engagement", { page_click: pathName });
    if (pathName == "/membership") {
      window.open(
        "https://join.mywallet.deals/?id=c437257f-71b2-45a7-9190-3f91cf78de8a&m_location=/#/login",
        "_blank"
      );
    } else if (pathName == "/careers") {
      let careersURL =
        "https://docs.google.com/forms/d/e/1FAIpQLSfeqmotU-7Ko8SFYVzBHIp4JgnF3APcWB2yKpi_LpiBHzj2zA/viewform";
      window.open(careersURL, "_blank", "noopener,noreferrer");
    } else {
      navigate(pathName);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [swiper, setSwiper] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (swiper) {
        swiper.update();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [swiper]);

  const Images = banners.map((banner) => banner.images);

  const sliderImages = Images.flat().map((image, index) => ({
    desktop: image.desktop,
    mobile: image.mobile,
    productLink: image.productLink,
    alt: `Slider Image ${index + 1}`,
  }));
  return (
    <>
      {!cookies["age-verified"] && <AgeVerification></AgeVerification>}

      <IndexNavbar fixed />
      <StockBanner></StockBanner>

      {sliderImages.length > 0 && (
        <Swiper
          style={{ width: "100%" }}
          navigation={true}
          loop={true}
          className="mySwiper"
          onSwiper={setSwiper}
        >
          {sliderImages.map((image, index) => (
            <SwiperSlide key={index} style={{ height: "auto" }}>
              <a
                // href={image.productLink || "#"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={width >= 769 ? image.desktop : image.mobile}
                  alt={image.alt}
                  style={{ display: "block", width: "100%", height: "100%" }}
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <section
        className="py-6 header welcomeBackgroundColor h-screen mobileStyleBG"
        style={{
          backgroundImage: require("assets/img/bannerBG.png").default,
        }}
      >
        <div className="container justify-center mx-auto relative items-center flex flex-row ">
          <div className="text-center  flex flex-wrap sm:w-10/12 md:w-6/12 lg:w-6/12 xl:w-6/12 p-5">
            <div className="sm:w-full md:w-full">
              <div className="sm:w-full md:w-full">
                <img
                  alt="..."
                  className="mx-auto indexLogo"
                  src={require("assets/img/logoGlow.png")}
                />
              </div>
              <div className=" sm:pt-0 md:mt-3 lg:pt-3">
                <h2 className="animate-charcter">Price Match Guarantee</h2>
                <p
                  className="mt-4 text-lg font-normal leading-relaxed text-white"
                  style={{ textShadow: "-2px 4px 4px #e95094" }}
                >
                  We are committed to offering you the best value for your
                  dollar with the lowest prices — guaranteed! If you find a
                  lower price on an identical item, we'll match it!
                </p>
                <div
                  className="mt-6 md:mt-12 lg:mt-12 xl:mt-12 w-1/2"
                  style={{
                    width: "250px",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <button
                    onClick={() => {
                      routeChange("/stores");
                    }}
                    className="ocmOutline w-full get-started text-white font-bold  py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Shop Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:mt-16 xl:mt-di">
          <span>
            <img
              className="mx-auto"
              style={{ height: "100%", width: "100%", maxWidth: "40px" }}
              src={require("assets/img/downIcon.png")}
              alt="..."
              onClick={executeScroll}
            />
          </span>
        </div>
      </section>

      <div
        ref={myRef}
        className="header h-screen px-12 relative items-center flex flex-wrap custom-background"
      >
        <div className="container mx-auto ">
          <div className="flex flex-col items-center justify-center">
            <div className="text-center">
              <h3 className="text-3xl font-semibold text-white">
                Enroll In Our Membership Program
              </h3>
              <p className="mt-4 text-lg leading-relaxed text-white">
                Start Earning And Redeeming Awesome Rewards Today!
              </p>
            </div>

            <div className="w-full">
              <div className="flex-auto mx-auto items-center text-center pt-3">
                <button
                  onClick={() => {
                    routeChange("/membership");
                  }}
                  style={{ width: "250px" }}
                  className="ocmOutline w-full get-started text-white font-bold py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue2-500 active:bg-lightBlue2-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {(function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 3251138, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")}
    </>
  );
}
